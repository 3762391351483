




















































import {defineComponent, PropType} from '@vue/composition-api';
import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import TableInput from '@/tasks/components/inputs/TableInput.vue';
import {VTextField} from 'vuetify/lib/components';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default defineComponent({
  name: 'uOttawa1321Lab6Procedure',
  components: {StembleLatex, TableInput, STextarea, CalculationInput, VTextField},
  mixins: [DynamicQuestionMixin()],
  props: {
    isMarking: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      inputs: {
        procedure: null,
        observations: null,
        language: 'en',
      },
      questions: [
        {
          en: 'This section should describe what YOU actually did and observed. Do not recopy the procedure from the manual. You should summarize the procedure (between 5 and 15 steps at most, as few as possible) using the exact quantities that you used in the steps. Reaction steps, workup steps and analyses should all be included. No explanation of observations is required.',
          fr: "Cette section doit décrire ce que VOUS avez réellement fait et observé. Ne recopiez pas la procédure du manuel. Vous devez résumer la procédure (entre 5 et 15 étapes au maximum, aussi peu que possible) en utilisant les quantités exactes que vous avez utilisées dans les étapes. Les étapes de réaction, les étapes de traitement et les analyses doivent toutes être incluses. Aucune explication des observations n'est requise.",
        },
        {
          en: 'Report all observations as they occurred - not as described for the average experiment in the lab manual. If you have heated a solution for 35 minutes instead of the 30 minutes suggested, record 35 minutes. You should include the visual appearance of reagents (e.g., pale yellow liquid, colorless crystals, white powder) and any observations made during the reaction (colour change, bubbles, smells, etc.).',
          fr: "Signalez toutes les observations telles qu'elles se sont produites - non pas comme décrit pour l'expérience moyenne dans le manuel de laboratoire. Si vous avez chauffé une solution pendant 35 minutes au lieu des 30 minutes suggérées, enregistrez 35 minutes. Vous devez inclure l'apparence visuelle des réactifs (par exemple, liquide jaune pâle, cristaux incolores, poudre blanche) et toutes les observations faites pendant la réaction (changement de couleur, formation de bulles, odeurs, etc.).",
        },
      ],
    };
  },
  methods: {
    getQuestionText(question: any): string {
      return this.inputs.language === 'en' ? question.en : question.fr;
    },
  },
});
